import { parseISO } from 'date-fns'
import * as R from 'ramda'

import { equals } from '../Domain/River'
import {
  type BargeNominationFilters,
  type BargeNominationRequest,
  type NominatedBarge as GqlFullNominatedBarge,
  type NominatedTbnBarge as GqlNominatedTbnBarge,
  type OrderRevisionId as GqlOrderRevisionId,
  type OverviewNomination,
  type OverviewNominationVersion as GqlNominationVersionFull,
  OverviewNominationVersionType,
  type RiverLocationLite,
  TboSubmissionStatusId,
  type TowConfiguration,
} from '../generated/graphql'

import type {
  ExternalNominationVersionData,
  NativeNominationVersionData,
  NominatedBarge,
  NominationData,
  NominationVersionData,
  OrderRevisionId,
  StopsWithMetrics,
  TbnBarge,
  Tow,
  VersionedNominationBargeFilers,
  VersionedNominationRequest,
  VersionedNominationTowConfiguration,
} from './models'
import type { NominatedTow } from '../Domain/Nomination'

export type GqlNomination = Omit<OverviewNomination, 'ownerId'>

export type GqlNominationRequest = BargeNominationRequest

export function convertNomination(gqlNomination: GqlNomination): NominationData {
  const n = R.pick(['id', 'slug', 'recordTime', 'tboLinkStatus', 'tboNumber'], gqlNomination)
  return { ...n, recordTime: parseISO(n.recordTime) }
}

function convertTowConfiguration(towConfiguration: TowConfiguration): VersionedNominationTowConfiguration {
  return R.pick(
    ['boat', 'goal', 'hasTurnboat', 'numberOfBarges', 'numberOfEmptyBarges', 'preselectedBarges'],
    towConfiguration
  )
}

function convertBargeFilters(bargeFilters: BargeNominationFilters): VersionedNominationBargeFilers {
  const filters = R.pick(
    [
      'excludeBargeIds',
      'excludeBargeTypes',
      'excludeTboInfoBarges',
      'excludeTripStatuses',
      'excludeShuttleMoves',
      'lane',
      'maximumDraft',
      'expectedDepartureTime',
      'towOrigin',
      'towDestination',
    ],
    bargeFilters
  )
  return {
    ...filters,
    expectedDepartureTime: filters.expectedDepartureTime ? parseISO(filters.expectedDepartureTime) : null,
  }
}

export function convertNominationRequest(gqlTowConfiguration: GqlNominationRequest): VersionedNominationRequest {
  const { bargeFilters, prioritizeHotBarges, towConfiguration } = gqlTowConfiguration
  return {
    towConfiguration: convertTowConfiguration(towConfiguration),
    bargeFilters: convertBargeFilters(bargeFilters),
    prioritizeHotBarges,
  }
}
export type GqlTowBuildOrder = { latestInfo: string | null }

export function convertTowBuildOrder(tbo: GqlTowBuildOrder) {
  return R.pick(['latestInfo'], tbo)
}

export type GqlNominatedBarge = Omit<GqlFullNominatedBarge, 'towId' | 'isScheduledForPickup' | 'towBuildOrder'> & {
  towBuildOrder: { latestInfo: string | null } | null
}

export function convertNominatedBarge(gqlNominatedBarge: GqlNominatedBarge): NominatedBarge {
  const bargeBase = R.pick(
    [
      'id',
      'name',
      'cargo',
      'hullType',
      'isPreselected',
      'isHot',
      'riskLevel',
      'pickupType',
      'tripStatus',
      'destination',
      'currentLocation',
      'loadStatus',
      'expectedLoadStatus',
      'pickupFacility',
      'dropOffFacility',
      'towBuildOrder',
      'type',
      'fleet',
      'distanceToPickup',
    ],
    gqlNominatedBarge
  )
  return {
    ...bargeBase,
    towBuildOrder: gqlNominatedBarge.towBuildOrder ? convertTowBuildOrder(gqlNominatedBarge.towBuildOrder) : null,
  }
}

export function convertTbnBarge(tbnBarge: GqlNominatedTbnBarge): TbnBarge {
  return R.pick(['pickupFacility', 'dropOffFacility', 'expectedLoadStatus'], tbnBarge)
}

export type GqlStopsWithMetrics = {
  dwellMinutes: number | null
  distanceToNextStop: number | null
  travelMinutesToNextStop: number | null
  stop: RiverLocationLite
}

export function convertStopsWithMetrics(gqlStop: GqlStopsWithMetrics, barges: NominatedBarge[]): StopsWithMetrics {
  const base = R.pick(['dwellMinutes', 'distanceToNextStop', 'travelMinutesToNextStop', 'stop'], gqlStop)
  const bargesToPickup = R.filter(barge => equals(gqlStop.stop, barge.pickupFacility), barges)
  const bargesToDrop = R.filter(barge => equals(gqlStop.stop, barge.dropOffFacility), barges)
  return {
    ...base,
    bargesToPickup,
    bargesToDrop,
  }
}

type GqlNominatedTow = NominatedTow

export function convertTow(gqlTow: GqlNominatedTow): Tow {
  const barges = R.map(convertNominatedBarge, gqlTow.barges)

  return {
    barges,
    tbnBarges: R.map(convertTbnBarge, gqlTow.tbnBarges),
    hasTurnboat: gqlTow.hasTurnboat,
    boat: R.pick(['boatId', 'name', 'abbreviation'], gqlTow.boat),
    stopsWithMetrics: R.map(s => convertStopsWithMetrics(s, barges), gqlTow.stopsWithMetrics),
    efficiencyMetric: gqlTow.efficiencyMetric,
  }
}

function convertOrderRevisionId(gqlOrderRevisionId: GqlOrderRevisionId): OrderRevisionId {
  return R.pick(['number', 'headerId', 'revisionNumber'], gqlOrderRevisionId)
}

export type GqlNominationVersion = Omit<
  GqlNominationVersionFull,
  'ownerId' | 'crossEfficiencyGoalScores' | 'nominationId' | 'tow' | 'nominationRequest' | 'review'
> & {
  tow: GqlNominatedTow
  nominationRequest: GqlNominationRequest | null
}

function convertNativeNominationVersion(gqlNominationVersion: GqlNominationVersion): NativeNominationVersionData {
  return {
    id: gqlNominationVersion.id,
    slug: gqlNominationVersion.slug,
    recordTime: parseISO(gqlNominationVersion.recordTime),
    type: OverviewNominationVersionType.Native,
    nominationRequest: convertNominationRequest(gqlNominationVersion.nominationRequest!),
    tow: convertTow(gqlNominationVersion.tow),
    review: {},
    tboSubmissionStatus: gqlNominationVersion.tboSubmissionStatus ?? TboSubmissionStatusId.NotSubmitted,
    orderRevisionId: gqlNominationVersion.orderRevisionId
      ? convertOrderRevisionId(gqlNominationVersion.orderRevisionId)
      : null,
  }
}

function convertExternalNominationVersion(gqlNominationVersion: GqlNominationVersion): ExternalNominationVersionData {
  return {
    id: gqlNominationVersion.id,
    slug: gqlNominationVersion.slug,
    recordTime: parseISO(gqlNominationVersion.recordTime),
    type: OverviewNominationVersionType.External,
    tow: convertTow(gqlNominationVersion.tow),
    tboSubmissionStatus: TboSubmissionStatusId.Submitted,
    orderRevisionId: convertOrderRevisionId(gqlNominationVersion.orderRevisionId!),
  }
}

export function convertNominationVersion(gqlNominationVersion: GqlNominationVersion): NominationVersionData {
  return gqlNominationVersion.type === ('Native' as OverviewNominationVersionType)
    ? convertNativeNominationVersion(gqlNominationVersion)
    : convertExternalNominationVersion(gqlNominationVersion)
}
